import { SearchRequest } from '../../../../services/Filters/FilterTypes';
import { SECTIONS } from '../../../../types';

export type LastSearchType = {
  searchQueryUpdated: SearchRequest;
  titleSearch?: string;
  area: any;
  numberOfFilters: string;
};

export const formattedSection: { [key in SECTIONS]: string } = {
  [SECTIONS.BUY]: 'Buy',
  [SECTIONS.RENT]: 'Rent',
  [SECTIONS.SHARE]: 'Share',
  [SECTIONS.SOLD]: 'Sold',
  [SECTIONS.RECENT]: 'Recent',
  [SECTIONS.COMMERCIAL_BUY]: 'Commercial Rent',
  [SECTIONS.COMMERCIAL_RENT]: 'Commercial Buy',
  [SECTIONS.STUDENT_ACCOMMODATION_RENT]: 'Student Rent',
  [SECTIONS.STUDENT_ACCOMMODATION_SHARE]: 'Student Share',
  [SECTIONS.PARKING_BUY]: 'Parking Buy',
  [SECTIONS.PARKING_RENT]: 'Parking Rent',
  [SECTIONS.NEW_HOMES]: 'New Homes',
  [SECTIONS.HOLIDAY_HOMES]: 'Holiday Homes',
  [SECTIONS.INTERNATIONAL_BUY]: 'Overseas Buy',
  [SECTIONS.INTERNATIONAL_RENT]: 'Overseas Rent',

  // Below sections only available on agents domain, which won't have Last Search functionality
  [SECTIONS.VT_PROPERTY_PRICE_REGISTER]: '',
  [SECTIONS.VT_RESIDENTIAL_RENT]: '',
  [SECTIONS.VT_RESIDENTIAL_SALES]: '',
  [SECTIONS.VT_COMMERCIAL]: '',
};
