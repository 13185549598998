import styled from 'styled-components';

import { PAGE_BREAK_POINT_800PX } from '../../../types/breakpoints';

export const StyledDiv = styled.div`
  background: ${({ theme }) => theme.color.WHITE};
  border-radius: ${({ theme }) => theme.border.radius.STANDARD};
  padding: ${({ theme }) => `${theme.spacing.M16} ${theme.spacing.M16}`};
  max-width: 848px;
  margin: 0 auto;
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.16);

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    padding: ${({ theme }) => `${theme.spacing.M32} ${theme.spacing.L64}`};
  }
`;
