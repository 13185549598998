import React, { useState, ReactElement } from 'react';

interface TabsProps {
  activeTab: number;
  children: (stateAndHelpers: {
    active: number;
    setActive: (tab: number) => void;
  }) => ReactElement;
}

export const Tabs = ({
  activeTab,
  children,
}: TabsProps): React.ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab);

  const handleChange = (tab: number) => {
    setActiveTabIndex(tab);
  };

  const getStateAndHelpers = () => {
    return {
      active: activeTabIndex,
      setActive: handleChange,
    };
  };

  return children(getStateAndHelpers());
};
