import styled from 'styled-components';

import { PAGE_BREAK_POINT_480PX } from '../../../../types/breakpoints';

export const Container = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.S8};
  @media screen and (min-width: ${PAGE_BREAK_POINT_480PX}) {
    margin-top: ${({ theme }) => theme.spacing.M16};
  }

  a {
    margin-bottom: 0;
    border: ${({ theme }) => theme.border.width.STANDARD} solid
      ${({ theme }) => theme.color.GREY_LIGHTER};
    padding: ${({ theme }) => `${theme.spacing.M16}`};
  }

  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: bottom;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 190px;

    /* hardcoded max-width because component is flex:nowrap by default */
    @media screen and (min-width: 375px) {
      max-width: 234px;
    }

    @media screen and (min-width: 425px) {
      max-width: 290px;
    }

    @media screen and (min-width: ${PAGE_BREAK_POINT_480PX}) {
      max-width: 100%;
    }
  }

  #undo-icon {
    min-width: ${({ theme }) => theme.spacing.M24};
    height: ${({ theme }) => theme.spacing.M24};
  }
`;
