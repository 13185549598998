import styled from 'styled-components';

import { NewButton } from '../../../components/Toolkit/NewButton/NewButton';
import {
  PAGE_BREAK_POINT_1004PX,
  PAGE_BREAK_POINT_1080PX,
  PAGE_BREAK_POINT_640PX,
  PAGE_BREAK_POINT_800PX,
} from '../../../types/breakpoints';

import { StyledProps } from './TabsPanels.types';

export const Container = styled.div`
  margin: 0 auto 16px;
  @media only screen and (min-width: ${PAGE_BREAK_POINT_640PX}) {
    min-height: 230px;
  }
`;

export const StyledH3 = styled.h3`
  max-width: 1040px;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.fontSize.T18}
  line-height: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  margin: ${({ theme }) =>
    `${theme.spacing.L40} ${theme.spacing.M16} ${theme.spacing.M16} ${theme.spacing.M16}`};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    ${({ theme }) => theme.fontSize.T20}
    margin: ${({ theme }) =>
      `${theme.spacing.M32} ${theme.spacing.M16} ${theme.spacing.M16} ${theme.spacing.M16}`};
    line-height: 24px;
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT_1080PX}) {
    margin: ${({ theme }) => `${theme.spacing.M32} auto ${theme.spacing.M16}`};
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${({ theme }) => `0 ${theme.spacing.M16}`};
  border-bottom: ${({ theme }) => theme.border.width.STANDARD} solid
    ${({ theme }) => theme.color.GREY_LIGHTER};
  @media only screen and (min-width: ${PAGE_BREAK_POINT_1080PX}) {
    max-width: 1040px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }
`;

export const Tab = styled(NewButton)<StyledProps>`
  padding: ${({ theme }) => `${theme.spacing.S12} 0`};
  display: flex;
  justify-content: center;
  border-radius: 0;
  ${({ theme }) => theme.fontSize.S14};

  ${({ isActive }) =>
    isActive
      ? `border-bottom: 3px solid #4170C4; font-weight: 600; color: #4170C4`
      : 'color: #333333;'}

  &:active {
    color: ${({ theme }) => theme.color.PRIMARY};
    font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    ${({ theme }) => theme.fontSize.S16};
  }
`;

export const TabContent = styled.ul<{ isActive: boolean; totalLinks: number }>`
  padding: ${({ theme }) => `${theme.spacing.M24} ${theme.spacing.M16}`};
  margin: 0 auto;
  flex-flow: column wrap;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  a {
    margin-bottom: ${({ theme }) => `${theme.spacing.S8}`};
    border: ${({ theme }) => theme.border.width.STANDARD} solid
      ${({ theme }) => theme.color.GREY_LIGHTER};
    padding: ${({ theme }) => `${theme.spacing.M16}`};

    p:first-child {
      margin-bottom: ${({ theme }) => `${theme.spacing.S4}`};
    }

    svg {
      width: ${({ theme }) => `${theme.spacing.S12}`};
      height: ${({ theme }) => `${theme.spacing.S12}`};
      color: ${({ theme }) => theme.color.GREY_DARK};
    }
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT_1004PX}) {
    padding: ${({ theme }) => `${theme.spacing.M32} ${theme.spacing.M16}`};
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 1040px;

    a {
      width: 490px;
      margin-bottom: ${({ theme }) => `${theme.spacing.M24}`};
      padding: ${({ theme }) => `${theme.spacing.M24}`};

      p:first-child {
        ${({ theme }) => theme.fontSize.S16};
      }
    }
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT_1080PX}) {
    padding: ${({ theme }) => `${theme.spacing.M32} 0`};

    a {
      width: 508px;
    }
  }
`;

export const ImagePanel = styled.img`
  margin: ${({ theme }) => `0 ${theme.spacing.M16} 0 0`};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_1080PX}) {
    margin: ${({ theme }) => `0 ${theme.spacing.M24} 0 0`};
  }
`;
