import styled from 'styled-components';

import {
  PAGE_BREAK_POINT_1004PX,
  PAGE_BREAK_POINT_800PX,
} from '../../../types/breakpoints';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.li`
  min-width: 114px;
  margin-right: ${({ theme }) => theme.spacing.S8};
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  :last-of-type {
    margin-right: 0;
  }
  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    min-width: 144px;
    margin-bottom: ${({ theme }) => theme.spacing.S12};
  }
`;

export const Wrapper = styled.div`
  @media only screen and (min-width: 768px) and (max-width: ${PAGE_BREAK_POINT_1004PX}) {
    padding: ${({ theme }) => `0 ${theme.spacing.M24}`};
    margin-top: -32px;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.fontSize.T18};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  line-height: ${({ theme }) => theme.spacing.M24};
  margin-bottom: ${({ theme }) => theme.spacing.M24};

  @media only screen and (min-width: 800px) {
    ${({ theme }) => theme.fontSize.T20};
    font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
    line-height: 28px;
  }
`;
