import { CategoryName, HOMEPAGE_URLS, SEARCH_URLS } from '../../../types';

export type QuickLinksProps = {
  category: CategoryName;
  activeSection?: string;
  title?: string;
};

export type QuickLink = {
  route: string;
  linkText: string;
  icon: boolean;
};

export type QuickLinksConfig = {
  [key: string]: QuickLink[];
};

export const quickLinksInfo: QuickLinksConfig = {
  RESIDENTIAL: [
    {
      route: `/${SEARCH_URLS.BUY}/ireland`,
      linkText: 'For Sale',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.RENT}/ireland`,
      linkText: 'To Rent',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.SHARE}/ireland`,
      linkText: 'Sharing',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.SOLD}/ireland`,
      linkText: 'Sold',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.NEW_HOMES}/ireland`,
      linkText: 'New Homes',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.HOLIDAY_HOMES}/ireland`,
      linkText: 'Holiday Homes',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.INTERNATIONAL_BUY}/worldwide`,
      linkText: 'Overseas',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.PARKING_RENT}/ireland`,
      linkText: 'Parking',
      icon: false,
    },
    {
      route: `/${HOMEPAGE_URLS.COMMERCIAL}`,
      linkText: 'Commercial',
      icon: true,
    },
    {
      route: `/${HOMEPAGE_URLS.STUDENT_ACCOMMODATION}`,
      linkText: 'Student Accommodation',
      icon: true,
    },
  ],

  COMMERCIAL_RENT: [
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland`,
      linkText: 'Commercial',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/office-spaces`,
      linkText: 'Office Space',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/retail-units`,
      linkText: 'Retail Unit',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/industrial-units`,
      linkText: 'Industrial Unit',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/restaurants-bars-hotels`,
      linkText: 'Restaurant / Bar / Hotel',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/commercial-sites`,
      linkText: 'Commercial Site',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/agricultural-land`,
      linkText: 'Agricultural Land',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/development-land`,
      linkText: 'Development Land',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/industrial-sites`,
      linkText: 'Industrial Site',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/office-shares`,
      linkText: 'Office Share',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_RENT}/ireland/serviced-offices`,
      linkText: 'Serviced Office',
      icon: false,
    },
  ],

  COMMERCIAL_BUY: [
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland`,
      linkText: 'Any Property',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/office-spaces`,
      linkText: 'Office Space',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/retail-units`,
      linkText: 'Retail Unit',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/industrial-units`,
      linkText: 'Industrial Unit',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/restaurants-bars-hotels`,
      linkText: 'Restaurant / Bar / Hotel',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/commercial-sites`,
      linkText: 'Commercial Site',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/agricultural-land`,
      linkText: 'Agricultural Land',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/development-land`,
      linkText: 'Development Land',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/industrial-sites`,
      linkText: 'Industrial Site',
      icon: false,
    },
    {
      route: `/${SEARCH_URLS.COMMERCIAL_BUY}/ireland/investment-properties`,
      linkText: 'Investment Property',
      icon: false,
    },
  ],
};
