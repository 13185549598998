import React, { FC } from 'react';

import { AndroidIcon, AppleIcon } from '@dsch/icons';
import styled from 'styled-components';

import { SEOInfo } from '../../../config/popularAreas';

import routes from '../../../server/routes';
const { Link } = routes;

const HeroSEOContentWrapper = styled.div`
  max-width: 300px;
  margin: auto;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  @media only screen and (min-width: 640px) {
    max-width: 700px;
  }

  @media only screen and (max-width: 321px) {
    max-width: 280px;
    top: 20px;
  }
`;

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  @media only screen and (min-width: 1040px) {
    font-size: 16px;
  }
`;

const StyledSpan = styled.span`
  font-weight: 400;
  display: block;
`;

const AdditionalStyledSEO = styled.div`
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  max-width: 300px;
  left: 0;
  right: 0;
  @media only screen and (min-width: 640px) {
    max-width: 1000px;
    bottom: 40px;
    padding: 16px 20px;
  }
`;

const MessageWrapper = styled.div`
  background-color: #f4f4f4;
  padding: 16px;
  height: 102px;
  display: flex;
  border-radius: 4px;
  @media only screen and (min-width: 640px) {
    height: auto;
  }
`;

const MessageH2 = styled.h2`
  line-height: 22px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  justify-content: center;
  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
`;

const A = styled.a`
  font-weight: 600;
  text-decoration: underline;
  color: #333333;
  :visited {
    color: #333333;
  }
  :hover {
    cursor: pointer;
  }
`;

const PromotionalCopyContainer = styled.div`
  display: flex;
`;

const PromotionalCopyTitle = styled.p<{ hasMultipleLinks: boolean }>`
  ${({ hasMultipleLinks }) => hasMultipleLinks && 'margin-bottom: 16px;'}
  @media only screen and (min-width: 640px) {
    margin-bottom: 0;
  }
`;

const PromotionalCopy = styled.div`
  display: flex;
  align-items: center;
  :not(:last-of-type) {
    margin-right: 24px;
  }
  @media only screen and (min-width: 640px) {
    :not(:last-of-type) {
      margin: 0 20px 0 16px;
    }
  }
`;

const IconSpan = styled.span`
  margin-right: 8px;
  margin-top: 2px;
`;

export const Icons: { [key: string]: JSX.Element } = {
  ANDROID: <AndroidIcon />,
  APPLE: <AppleIcon />,
};

export type SEOContentProps = {
  SEOContent: SEOInfo;
};

export const HeroSEOContent: FC<SEOContentProps> = ({
  SEOContent,
}: SEOContentProps) => (
  <HeroSEOContentWrapper>
    <StyledH2 data-testid="homepage-hero-seo">
      {SEOContent.SEOTitle}
      <StyledSpan>{SEOContent.Strapline}</StyledSpan>
    </StyledH2>
  </HeroSEOContentWrapper>
);

export const AdditionalSEO: FC<SEOContentProps> = ({
  SEOContent,
}: SEOContentProps) => {
  const { additionalMessage } = SEOContent;
  const hasMultipleLinks = additionalMessage.links.length > 1 ? true : false;
  return (
    <AdditionalStyledSEO>
      <MessageWrapper>
        <MessageH2 data-testid="homepage-additional-seo">
          <PromotionalCopyTitle hasMultipleLinks={hasMultipleLinks}>
            {additionalMessage.title}&nbsp;
          </PromotionalCopyTitle>
          <PromotionalCopyContainer>
            {additionalMessage.links[0].external ? (
              <PromotionalCopy>
                {additionalMessage.links[0].icon && (
                  <IconSpan>{Icons[additionalMessage.links[0].icon]}</IconSpan>
                )}
                <A
                  data-testid="homepage-additional-seo-link"
                  href={additionalMessage.links[0].url}
                >
                  {additionalMessage.links[0].name}
                </A>
              </PromotionalCopy>
            ) : (
              <PromotionalCopy>
                {additionalMessage.links[0].icon && (
                  <IconSpan>{Icons[additionalMessage.links[0].icon]}</IconSpan>
                )}
                <Link
                  route={additionalMessage.links[0].url}
                  passHref
                  legacyBehavior
                >
                  <A data-testid="homepage-additional-seo-link">
                    {additionalMessage.links[0].name}
                  </A>
                </Link>
              </PromotionalCopy>
            )}
            {additionalMessage.links[1] && (
              <>
                {additionalMessage.links[1].external ? (
                  <PromotionalCopy>
                    {additionalMessage.links[1].icon && (
                      <IconSpan>
                        {Icons[additionalMessage.links[1].icon]}
                      </IconSpan>
                    )}
                    <A
                      data-testid="homepage-additional-seo-link"
                      href={additionalMessage.links[1].url}
                    >
                      {additionalMessage.links[1].name}
                    </A>
                  </PromotionalCopy>
                ) : (
                  <PromotionalCopy>
                    {additionalMessage.links[1].icon && (
                      <IconSpan>
                        {Icons[additionalMessage.links[1].icon]}
                      </IconSpan>
                    )}
                    <Link
                      route={additionalMessage.links[1].url}
                      passHref
                      legacyBehavior
                    >
                      <A data-testid="homepage-additional-seo-link">
                        {additionalMessage.links[1].name}
                      </A>
                    </Link>
                  </PromotionalCopy>
                )}
              </>
            )}
          </PromotionalCopyContainer>
        </MessageH2>
      </MessageWrapper>
    </AdditionalStyledSEO>
  );
};
