import { theme } from '../../../helpers/styles/Theme';

type TextStyle = {
  fontSize: string;
  fontWeight: string;
  bottomMargin: string;
};

export type ThemeProps = {
  title: TextStyle;
  subtitle: TextStyle;
  panelPadding: string;
  imageBottomMargin?: string;
  closeIconSize?: string;
  closeIconMargin: string;
  horizontal?: boolean;
  border?: {
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    borderRadius: string;
  };
};

export const panelThemes: Map<string, ThemeProps> = new Map([
  [
    'DEFAULT_VERTICAL_X_SMALL',
    {
      title: {
        fontSize: theme.fontSize.S14,
        fontWeight: theme.fontWeight.SEMIBOLD,
        bottomMargin: theme.spacing.S4,
      },
      subtitle: {
        fontSize: theme.fontSize.B12,
        fontWeight: theme.fontWeight.REGULAR,
        bottomMargin: theme.spacing.S12,
      },
      panelPadding: theme.spacing.M16,
      imageBottomMargin: theme.spacing.S12,
      closeIconMargin: theme.spacing.S12,
      border: {
        borderColor: theme.color.GREY_LIGHTER,
        borderStyle: 'solid',
        borderWidth: theme.border.width.STANDARD,
        borderRadius: theme.border.radius.STANDARD,
      },
    },
  ],
  [
    'DEFAULT_VERTICAL_SMALL',
    {
      title: {
        fontSize: theme.fontSize.S14,
        fontWeight: theme.fontWeight.SEMIBOLD,
        bottomMargin: theme.spacing.S8,
      },
      subtitle: {
        fontSize: theme.fontSize.S14,
        fontWeight: theme.fontWeight.REGULAR,
        bottomMargin: theme.spacing.M24,
      },
      panelPadding: theme.spacing.M24,
      imageBottomMargin: theme.spacing.M16,
      closeIconMargin: theme.spacing.S12,
      border: {
        borderColor: theme.color.GREY_LIGHTER,
        borderStyle: 'solid',
        borderWidth: theme.border.width.STANDARD,
        borderRadius: theme.border.radius.STANDARD,
      },
    },
  ],
  [
    'DEFAULT_VERTICAL_MEDIUM',
    {
      title: {
        fontSize: theme.fontSize.S16,
        fontWeight: theme.fontWeight.SEMIBOLD,
        bottomMargin: theme.spacing.S8,
      },
      subtitle: {
        fontSize: theme.fontSize.S14,
        fontWeight: theme.fontWeight.REGULAR,
        bottomMargin: theme.spacing.M24,
      },
      panelPadding: theme.spacing.M24,
      imageBottomMargin: theme.spacing.M16,
      closeIconMargin: theme.spacing.S12,
      border: {
        borderColor: theme.color.GREY_LIGHTER,
        borderStyle: 'solid',
        borderWidth: theme.border.width.STANDARD,
        borderRadius: theme.border.radius.STANDARD,
      },
    },
  ],
  [
    'DEFAULT_VERTICAL_LARGE',
    {
      title: {
        fontSize: theme.fontSize.T18,
        fontWeight: theme.fontWeight.SEMIBOLD,
        bottomMargin: theme.spacing.S8,
      },
      subtitle: {
        fontSize: theme.fontSize.S16,
        fontWeight: theme.fontWeight.REGULAR,
        bottomMargin: theme.spacing.M24,
      },
      panelPadding: theme.spacing.M32,
      imageBottomMargin: theme.spacing.M16,
      closeIconMargin: theme.spacing.S12,
      border: {
        borderColor: theme.color.GREY_LIGHTER,
        borderStyle: 'solid',
        borderWidth: theme.border.width.STANDARD,
        borderRadius: theme.border.radius.STANDARD,
      },
    },
  ],
  [
    'DEFAULT_HORIZONTAL_MEDIUM',
    {
      title: {
        fontSize: theme.fontSize.S16,
        fontWeight: theme.fontWeight.SEMIBOLD,
        bottomMargin: theme.spacing.S8,
      },
      subtitle: {
        fontSize: theme.fontSize.S14,
        fontWeight: theme.fontWeight.REGULAR,
        bottomMargin: theme.spacing.M24,
      },
      panelPadding: theme.spacing.M24,
      imageBottomMargin: theme.spacing.M16,
      closeIconMargin: theme.spacing.S12,
      horizontal: true,
      border: {
        borderColor: theme.color.GREY_LIGHTER,
        borderStyle: 'solid',
        borderWidth: theme.border.width.STANDARD,
        borderRadius: theme.border.radius.STANDARD,
      },
    },
  ],
]);
