import React from 'react';

import { safeGet } from '../../../helpers/utils';

import {
  AdBackground,
  AdPosition,
  BackgroundSpacing,
  StyledAdSlot,
} from './DFPContainer.styled';
import {
  minHeightMapArray,
  SizeMapping,
  UnitProps,
} from './DFPContainer.types';

// This ensures no unit is displayed until the first declaration of one
const generateSizeMap = (sizeMapping: SizeMapping[]) => [
  ...sizeMapping,
  {
    viewport: [0, 0] as [number, number],
    sizes: [],
  },
];

export const DFPUnit = ({
  AdSlot,
  adUnit,
  dfpDisabled,
  disableBackground,
  onSlotRender,
  reactDFPAdsReadyToLoad,
  shouldRefresh,
  sizeMapping,
  sizes,
  slotId,
  styles = {},
}: UnitProps) => {
  if (dfpDisabled) {
    return null;
  }

  const currentSizeMap = generateSizeMap(sizeMapping);

  const minHeightMapArray = currentSizeMap.reduce(
    (acc: minHeightMapArray, sizeMap) => {
      const [viewWidth] = sizeMap.viewport;
      const currentSizes = safeGet(sizeMap, ['sizes']);
      let minHeightObj = { width: 0, height: 0 };

      if (currentSizes) {
        minHeightObj = currentSizes.reduce(
          (
            targetHeightObj: { width: number; height: number },
            adSize: [number, number],
          ) => {
            const [width, height] = adSize ?? [0, 0];
            return height > targetHeightObj.height
              ? { width, height }
              : targetHeightObj;
          },
          minHeightObj,
        );
      }

      return [
        ...acc,
        {
          viewWidth,
          minHeightObj: {
            ...minHeightObj,
          },
        },
      ];
    },
    [],
  );

  return (
    <StyledAdSlot {...styles} data-testid={`dfp-slot-${slotId}`}>
      <AdPosition
        margin={styles.margin}
        padding={styles.padding}
        justifyContent={styles.justifyContent}
      >
        <AdBackground
          minHeightMapArray={minHeightMapArray}
          disableBackground={disableBackground}
        >
          {reactDFPAdsReadyToLoad && (
            <AdSlot
              adUnit={adUnit}
              onSlotRender={onSlotRender}
              shouldRefresh={shouldRefresh}
              sizeMapping={currentSizeMap}
              sizes={sizes}
              slotId={slotId}
              styles={styles}
            />
          )}
          <BackgroundSpacing
            disableBackground={disableBackground}
          ></BackgroundSpacing>
        </AdBackground>
      </AdPosition>
    </StyledAdSlot>
  );
};
