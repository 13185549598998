import React from 'react';
const Undo = (props: any) => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.5 10.5l3 3 3-3"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.556 13A9.002 9.002 0 0113.5 3a9 9 0 11-7.124 14.5M13.5 12V8.786M13.5 12l4 3.5"
    />
  </svg>
);

export default Undo;
