import styled from 'styled-components';
import {
  minHeightMap,
  minHeightMapArray,
  StylesForAdSlotType,
} from './DFPContainer.types';

export const StyledAdSlot = styled.div<StylesForAdSlotType>`
  .adBox {
    background: #ffffff;
    display: block;
    min-height: ${({ minHeight = '0' }) => minHeight};
    position: relative;
    text-align: ${({ textAlign = 'center' }) => textAlign};
    z-index: 100;

    &:not(:empty) {
      > div:first-child {
        display: inline-block;
      }
    }
  }

  @media print {
    display: none;
  }
`;

export const AdPosition = styled.div<{
  padding?: string;
  margin?: string;
  justifyContent?: string;
}>`
  display: flex;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0 auto' }) => margin};
`;

const viewportSorter = (a: minHeightMap, b: minHeightMap) => {
  const aViewWidth = a.viewWidth;
  const bViewWidth = b.viewWidth;
  if (aViewWidth > bViewWidth) {
    return 1;
  }

  if (aViewWidth < bViewWidth) {
    return -1;
  }
  return 0;
};

export const AdBackground = styled.div<{
  disableBackground: boolean;
  minHeightMapArray: minHeightMapArray;
}>`
  ${({ minHeightMapArray, disableBackground }) => {
    if (!minHeightMapArray || disableBackground) return '';
    const sortedMap = minHeightMapArray.sort(viewportSorter);

    return sortedMap.reduce((cssOutput, minHeightObj) => {
      return `
      ${cssOutput}
      @media only screen and (min-width: ${minHeightObj.viewWidth}px) {
        display: ${minHeightObj.minHeightObj.height === 0 ? ' none' : 'flex'};
        min-height: ${minHeightObj.minHeightObj.height}px;
        width: ${minHeightObj.minHeightObj.width}px;
        background: #ffffff;
        >div{
          flex: 1;
        }
      }
    `;
    }, '');
  }}
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const BackgroundSpacing = styled.span`
  left: calc(50% - 55px);
  position: absolute;
  top: calc(50% - 9px);
  z-index: 0;
  ${({ disableBackground }: { disableBackground: boolean }) =>
    disableBackground ? 'display: none;' : ''}
`;
