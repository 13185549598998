import routes from '../../../../server/routes';
import Undo from '../../../../tempIcons/Undo';

import { sortValues } from '../../../../components/Filters/Sort/Sort';
import ClickablePanel from '../../../../components/Toolkit/ClickablePanel/ClickablePanel';
import SmartLocalStorage from '../../../../helpers/storage/SmartLocalStorage';
import { sectionToSearchUrl } from '../../../../services/Search/sectionToSearchUrl';
import { SECTIONS } from '../../../../types';

import { useLastSearch } from './LastSearch.hook';
import { Container } from './LastSearch.styled';
import { LastSearchType } from './LastSearch.types';

export const LastSearch = ({ lastSearch }: { lastSearch: LastSearchType }) => {
  const { generateParams, getFormattedSection } = useLastSearch({
    searchQuery: lastSearch.searchQueryUpdated,
  });

  const {
    searchQueryUpdated: { section },
    titleSearch,
    numberOfFilters,
  } = lastSearch;

  const ROUTE_NAME = sectionToSearchUrl(section);

  const sortDisplayName = sortValues.find(
    (sort) => sort.value === lastSearch.searchQueryUpdated.sort,
  )?.displayName;

  const title = `Last search: ${titleSearch ?? 'Custom Area'}`;

  const sectionDisplayName = getFormattedSection(section as SECTIONS);

  const attributes = {
    routes: routes,
    route: ROUTE_NAME,
    params: generateParams(lastSearch),
  };

  const handleClick = () => {
    SmartLocalStorage.set('source', 'lastSearch');
  };
  return (
    <Container>
      <attributes.routes.Link
        passHref
        route={attributes.route}
        params={attributes.params}
        legacyBehavior
      >
        <ClickablePanel
          border={true}
          spaceBetween={'medium'}
          IconComponent={<Undo alt="Last search icon" id="undo-icon" />}
          content={{
            heading: title,
            subHeading: `${sectionDisplayName} - ${sortDisplayName} ${numberOfFilters}`,
          }}
          dataTracking="click_last_search"
          id="last-search-clickable-panel"
          href=""
          opensInSameTab={true}
          onClick={handleClick}
        />
      </attributes.routes.Link>
    </Container>
  );
};
