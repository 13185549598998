import React from 'react';

import ErrorBoundary from '../../../helpers/components/ErrorBoundary';

import { DFPUnit } from './DFPUnit';
import SmartSessionStorage from '../../../helpers/storage/SmartSessionStorage';
import { DFPProps, UnitProps } from './DFPContainer.types';

/*
 * This file holds the ErrorBoundary wrapper and all the exported functions for DFP (DoubleClick for Publishers)
 * It calls the DFP unit inside the error boundary to catch any errors that may occur
 */
export const DFPContainer = ({
  AdSlot,
  adUnit,
  dfpDisabled,
  disableBackground,
  onSlotRender,
  reactDFPAdsReadyToLoad,
  shouldRefresh,
  sizeMapping,
  sizes,
  slotId,
  styles = {},
}: UnitProps) => (
  <ErrorBoundary renderFallback={() => <></>}>
    <DFPUnit
      AdSlot={AdSlot}
      adUnit={adUnit}
      dfpDisabled={dfpDisabled}
      disableBackground={disableBackground}
      onSlotRender={onSlotRender}
      reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
      shouldRefresh={shouldRefresh}
      sizeMapping={sizeMapping}
      sizes={sizes}
      slotId={slotId}
      styles={styles}
    />
  </ErrorBoundary>
);

const getDfpTiling = () => {
  if (typeof window !== 'undefined') {
    if (SmartSessionStorage.get('tilingValue')) {
      return SmartSessionStorage.get('tilingValue');
    }

    const value = Math.floor(Math.random() * 10).toString();
    SmartSessionStorage.set('tilingValue', value);
    return value;
  }
};

export const getTargetingArguments = (
  targetingArguments: {
    [key: string]: string | [string];
  } = {},
  debugMode = false,
) => {
  const updatedArgs = { ...targetingArguments, tiling: getDfpTiling() };
  if (debugMode) {
    return { ...updatedArgs, site: 'debugmode' };
  }
  return { ...updatedArgs };
};

export const setDFPProps = (
  { targetingArguments, dfpNetworkId, collapseEmptyDivs = true }: DFPProps,
  debugMode = false,
) => ({
  dfpNetworkId,
  collapseEmptyDivs,
  targetingArguments: getTargetingArguments(targetingArguments, debugMode),
});

export const DFPRefresh = (googletag: string, DFPManager: any) => {
  if (googletag) {
    DFPManager.refresh();
  }
};

export const DFPLoad = (DFPManager: any) => {
  DFPManager.load();
};
