import styled from 'styled-components';

export const PageWrapper = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`;

/**
 * Container that is only visible on mobile
 */
export const MobileOnly = styled.div`
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const PrettyLink = styled.a`
  color: ${({ theme }) => theme.color.PRIMARY};
  text-decoration: underline;
`;
