import { SECTIONS, SEARCH_URLS, VT_SEARCH_URLS } from '../../types';

export const sectionToSearchUrl = (section: SECTIONS): string => {
  let routeName: string;
  switch (section) {
    case SECTIONS.BUY:
      routeName = SEARCH_URLS.BUY;
      break;
    case SECTIONS.RENT:
      routeName = SEARCH_URLS.RENT;
      break;
    case SECTIONS.SHARE:
      routeName = SEARCH_URLS.SHARE;
      break;
    case SECTIONS.SOLD:
      routeName = SEARCH_URLS.SOLD;
      break;
    case SECTIONS.RECENT:
      routeName = SEARCH_URLS.RECENT;
      break;
    case SECTIONS.COMMERCIAL_BUY:
      routeName = SEARCH_URLS.COMMERCIAL_BUY;
      break;
    case SECTIONS.COMMERCIAL_RENT:
      routeName = SEARCH_URLS.COMMERCIAL_RENT;
      break;
    case SECTIONS.STUDENT_ACCOMMODATION_RENT:
      routeName = SEARCH_URLS.STUDENT_ACCOMMODATION_RENT;
      break;
    case SECTIONS.STUDENT_ACCOMMODATION_SHARE:
      routeName = SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE;
      break;
    case SECTIONS.PARKING_BUY:
      routeName = SEARCH_URLS.PARKING_BUY;
      break;
    case SECTIONS.PARKING_RENT:
      routeName = SEARCH_URLS.PARKING_RENT;
      break;
    case SECTIONS.NEW_HOMES:
      routeName = SEARCH_URLS.NEW_HOMES;
      break;
    case SECTIONS.HOLIDAY_HOMES:
      routeName = SEARCH_URLS.HOLIDAY_HOMES;
      break;
    case SECTIONS.INTERNATIONAL_BUY:
      routeName = SEARCH_URLS.INTERNATIONAL_BUY;
      break;
    case SECTIONS.INTERNATIONAL_RENT:
      routeName = SEARCH_URLS.INTERNATIONAL_RENT;
      break;
    case SECTIONS.VT_PROPERTY_PRICE_REGISTER:
      routeName = VT_SEARCH_URLS.PROPERTY_PRICE_REGISTER;
      break;
    case SECTIONS.VT_RESIDENTIAL_RENT:
      routeName = VT_SEARCH_URLS.RESIDENTIAL_RENT;
      break;
    case SECTIONS.VT_RESIDENTIAL_SALES:
      routeName = VT_SEARCH_URLS.RESIDENTIAL_SALES;
      break;
    case SECTIONS.VT_COMMERCIAL:
      routeName = VT_SEARCH_URLS.COMMERCIAL;
      break;
  }
  return routeName;
};
