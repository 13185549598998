import React, { useEffect, useRef, useState } from 'react';

import { SearchIcon } from '@dsch/icons';
import dynamic from 'next/dynamic';
import AutosizeInput from 'react-input-autosize';
import routes from '../../server/routes';
import styled, { css } from 'styled-components';

import { SearchAPI } from '../../api';
import { HomepageDropdownProps } from '../../components/MultiInputTextSelect/HomepageDropdown';
import {
  NewButton,
  NewButtonSizes,
  NewButtonTypes,
} from '../../components/Toolkit/NewButton/NewButton';
import { getRootNode } from '../../helpers/utils';

import { TagStyled } from './CountyAreaFilter';
import { MultiInputTextSelect } from './MultiInputTextSelect';
import { AreaTag, SECTIONS } from '../../types';
import { DelayedSpinner } from '../Toolkit/Spinner/DelayedSpinner';

const HomepageDropdown = dynamic<HomepageDropdownProps>(
  () =>
    import(
      /*webpackChunkName: "HomepageDropdown-CHUNK"*/ '../../components/MultiInputTextSelect/HomepageDropdown'
    ).then((module) => module.HomepageDropdown),
  { ssr: false },
);

const MINIMUM_CHARS_BEFORE_SEARCH = 3;

const { Router } = routes;

type SearchBoxTypes = {
  id: string;
  isLoading: boolean;
  search: (selectedItems: AreaTag[]) => void;
  onFocus?: Function;
  onBlur?: Function;
  selectedSection: SECTIONS;
  onInputChange?: (userInput: string) => void;
  cleanup?: () => void;
  items: AreaTag[];
  isFocused: boolean;
  placeholderText: string;
  disablePropertyCount: boolean;
  selectedSectionURL: string;
  setIsLoading: (val: 1 | -1) => void;
};

export type subArea = {
  id: string;
  displayName: string;
  displayValue: string;
};

const topCornersRounded = css`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const InputValueWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 10;
  padding: ${({ theme }) => theme.spacing.S8};
  ${({ theme }) => theme.fontSize.B12}
  border: 1px solid ${({ theme }) => theme.color.GREY_LIGHTER};
  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? topCornersRounded : 'border-radius: 4px'};
  position: relative;
  cursor: text;
  min-height: 50px;
  max-height: 192px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.WHITE};

  @media only screen and (min-width: 640px) {
    justify-content: start;
    ${({ theme }) => theme.fontSize.S14}
    min-height: 70px;
    padding: ${({ theme }) => theme.spacing.S12};
  }
`;

const AutosizeInputStyled = styled(AutosizeInput)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.S4};
  border-radius: ${({ theme }) => theme.spacing.S4};
  height: ${({ theme }) => theme.spacing.M20};
  font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
  opacity: 0.9;
  input {
    /* setting width initially to 199 for iPhone 5, JS should override to correct size in AutoSize library */
    width: 199px;
    min-width: 198px;
    color: ${({ theme }) => theme.color.GREY_DARKER};
    border: none;
    cursor: text;
    outline: none;
    ${({ theme }) => theme.fontSize.S16}
    caret-color: ${({ theme }) => theme.color.GREY_DARK};
    height: 100%;
    &::-ms-clear {
      display: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.GREY_DARK};
    }
    @media only screen and (min-width: 640px) {
      margin-left: ${({ theme }) => theme.spacing.S12};

      ${({ theme }) => theme.fontSize.S14}
      height: 18px;
    }
  }
`;

const SearchIconStyled = styled(SearchIcon)`
  && {
    margin-right: ${({ theme }) => theme.spacing.S12};
    @media only screen and (min-width: 640px) {
      margin-left: ${({ theme }) => theme.spacing.S12};
    }
    color: ${({ theme }) => theme.color.GREY_DARK};
    width: ${({ theme }) => theme.spacing.M24};
    height: ${({ theme }) => theme.spacing.M24};
    flex-shrink: 0;
  }
`;

export const StyledDelayedSpinner = styled(DelayedSpinner)`
  position: absolute;
  z-index: 25;
  right: calc(50% - 20px);
  top: calc(50% - 20px);
  width: ${({ theme }) => theme.spacing.L40};
`;

const InputContainer = styled.div<{ shouldReverseRow?: boolean }>`
  display: flex;
  flex-direction: ${({ shouldReverseRow }) =>
    shouldReverseRow ? 'row-reverse' : 'row'};
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: start;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PlainInputText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  padding-left: ${({ children }) => (children ? '9px' : '0')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 150px;
  @media only screen and (min-width: 800px) {
    max-width: 350px;
  }
`;

export const Heading = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.BOLD};
  ${({ theme }) => theme.fontSize.S14}
  color: ${({ theme }) => theme.color.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.S12};
`;
const TagContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.S8};

  ${TagStyled} {
    margin: 5px 8px 4px 5px;
  }
`;

const SearchButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: ${({ theme }) => theme.spacing.S8};
`;

export const HomepageSearchBox = React.forwardRef(
  (props: SearchBoxTypes, ref: React.RefObject<any>) => {
    const {
      id,
      isLoading,
      setIsLoading,
      search,
      items,
      cleanup,
      placeholderText,
      disablePropertyCount,
      selectedSectionURL,
    } = props;

    const [currentSelectedItems, setCurrentSelectedItems] = useState<subArea[]>(
      [],
    );
    const [placeHolder, setPlaceHolder] = useState<string>(placeholderText);
    const [locationSubAreas, setLocationSubAreas] = useState<subArea[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<subArea | null>(
      null,
    );
    const wrapperRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
      if (locationSubAreas?.length) {
        setIsLoading(-1);
      }
    }, [locationSubAreas]);

    useEffect(() => {
      if (currentSelectedItems?.length) {
        // scroll to bottom of input everytime we add another tag to input box
        // large value is used here in case user adds many tags. This will always go to max bottom of the container
        if (wrapperRef.current) {
          wrapperRef.current.children[0].children[0].scrollTop = 10000;
        }
      }
    }, [currentSelectedItems]);

    const handleDropdownContent = async (item: subArea) => {
      setIsLoading(1);
      setSelectedLocation(item);
      const subAreas = await SearchAPI.getLocationSubAreas(item.id);
      setLocationSubAreas(subAreas);
      // scroll to the top of the areas list ("Menu" component) when we click on "Areas in _____" button
      if (wrapperRef.current) {
        wrapperRef.current.children[0].children[1].scrollTop = 0;
      }
    };

    const setFocus = (event?: any) => {
      if (event) {
        event.preventDefault();
      }
      setPlaceHolder('');
      props.onFocus && props.onFocus();
      document.addEventListener('click', unsetFocus, {
        capture: true,
      });
    };

    const unsetFocus = (event: any) => {
      if (event) {
        if (!ref) return;
        const rootNode = getRootNode(event.target);
        const dataTestId =
          rootNode.getAttribute && rootNode.getAttribute('data-testid');

        if (
          (wrapperRef as any)?.current?.contains(event.target) ||
          dataTestId === 'menu' ||
          dataTestId === 'tag' ||
          dataTestId === 'item' ||
          dataTestId?.includes('clickable-item')
        ) {
          return;
        }
      }
      props.onBlur && props.onBlur();
      setLocationSubAreas([]);
      setPlaceHolder(placeholderText);
      document.removeEventListener('click', unsetFocus);
    };

    const inputChangeMethod = (message: string) =>
      props.onInputChange && props.onInputChange(message);

    const buildQuery = () => {
      if (currentSelectedItems.length === 1) {
        Router.pushRoute(
          `${selectedSectionURL}/${currentSelectedItems[0].displayValue}`,
        );
        return;
      }

      const queryStr = currentSelectedItems.reduce(
        (acc: string, curr: subArea) => {
          acc += `location=${curr.displayValue}&`;
          return acc;
        },
        '?',
      );

      // Next router causes page to refresh atm
      // TODO: can be replaced with Next router when we add dynamic routing
      Router.pushRoute(
        `${selectedSectionURL}/ireland${queryStr.slice(
          0,
          queryStr.length - 1,
        )}`,
      );
    };

    const handleSelectedItem = (item: subArea) => {
      let currentSelectedCopy = [...currentSelectedItems];
      const isAlreadyAdded = currentSelectedCopy.findIndex(
        (selected: subArea) => selected.id === item.id,
      );

      if (isAlreadyAdded > -1) {
        currentSelectedCopy.splice(isAlreadyAdded, 1);
      } else {
        currentSelectedCopy = [...currentSelectedCopy, item];
      }

      setCurrentSelectedItems(currentSelectedCopy);
    };

    const removeItem = (id: string) => {
      const selectedItems = [...currentSelectedItems];
      const index = selectedItems.findIndex((item: subArea) => item.id === id);
      selectedItems.splice(index, 1);
      setCurrentSelectedItems(selectedItems);
    };

    const clearSubAreas = () => {
      setFocus();
      setLocationSubAreas([]);
    };

    const handleSearch = (
      e?:
        | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
        | undefined,
    ) => {
      e?.stopPropagation();
      buildQuery();
    };

    return (
      <div ref={wrapperRef}>
        <MultiInputTextSelect
          ref={ref}
          id={id}
          placeHolderText={placeHolder}
          onSelectionChange={search}
          minChars={3}
          items={items}
          onInputChange={inputChangeMethod}
          cleanup={cleanup && cleanup}
          isFocused={props.isFocused}
        >
          {({
            getAutoInputProps,
            getItemProps,
            selectedItems = [],
            highlightedIndex,
            getMenuProps,
            getInputWrapperProps,
            items = [],
            inputValue = '',
            clearInput,
          }: any) => {
            const isBelowTextLimit =
              inputValue.length < MINIMUM_CHARS_BEFORE_SEARCH;
            const hasSelectedItems = selectedItems.length > 0;
            const hasMatches = !isBelowTextLimit && items.length > 0;

            const showNoMatchText = !isBelowTextLimit && !hasMatches;

            const showAddLocation = isBelowTextLimit && !hasSelectedItems;

            const shouldShowMenu =
              hasMatches || showNoMatchText || showAddLocation;

            return (
              <div>
                <InputValueWrapper
                  {...getInputWrapperProps({
                    onFocus: setFocus,
                    isOpen: props.isFocused && shouldShowMenu,
                    onClick: setFocus,
                  })}
                >
                  <InputWrapper
                    data-testid="clickable-item"
                    onClick={clearSubAreas}
                  >
                    <InputContainer
                      shouldReverseRow={currentSelectedItems?.length < 1}
                    >
                      {currentSelectedItems?.length < 1 && <SearchIconStyled />}
                      {props.isFocused ? (
                        <TagContainer data-test-id="input-tag-container">
                          {currentSelectedItems.map((tag: any) => (
                            <TagStyled
                              data-testid="clickable-item"
                              key={tag.id}
                              onRemove={() => removeItem(tag.id)}
                            >
                              {tag}
                            </TagStyled>
                          ))}
                        </TagContainer>
                      ) : (
                        <PlainInputText onClick={setFocus}>
                          {currentSelectedItems
                            .map((tag: AreaTag) => tag.displayName)
                            .join(', ')}
                        </PlainInputText>
                      )}
                      {currentSelectedItems?.length < 1 ? (
                        <AutosizeInputStyled
                          data-hj-whitelist
                          {...getAutoInputProps({
                            'data-testid': 'county-area-filter',
                          })}
                        />
                      ) : null}
                    </InputContainer>
                    {props.isFocused && currentSelectedItems?.length > 0 && (
                      <SearchButtonContainer>
                        <NewButton
                          data-test-id="homepage-search-btn"
                          buttonText="SEARCH"
                          buttonSize={NewButtonSizes.SMALL}
                          buttonType={NewButtonTypes.PRIMARY}
                          onClick={handleSearch}
                        />
                      </SearchButtonContainer>
                    )}
                  </InputWrapper>
                </InputValueWrapper>
                {props.isFocused && (
                  <HomepageDropdown
                    locationSubAreas={locationSubAreas}
                    isLoading={isLoading}
                    clearSubAreas={clearSubAreas}
                    selectedLocation={selectedLocation}
                    currentSelectedItems={currentSelectedItems}
                    handleSelectedItem={handleSelectedItem}
                    showAddLocation={showAddLocation}
                    getItemProps={getItemProps}
                    inputValue={inputValue}
                    handleDropdownContent={handleDropdownContent}
                    clearInput={clearInput}
                    showNoMatchText={showNoMatchText}
                    getMenuProps={getMenuProps}
                    items={items}
                    highlightedIndex={highlightedIndex}
                    selectedSection={props.selectedSection}
                    disablePropertyCount={disablePropertyCount}
                    isBelowTextLimit={isBelowTextLimit}
                  />
                )}
              </div>
            );
          }}
        </MultiInputTextSelect>
      </div>
    );
  },
);
